<template lang="pug">
	.mb-4.d-flex.flex-wrap.justify-content-between.comebacker
		.d-flex.justify-content-between.align-items-center.w-100.comebacker__header
			dropdown-status(@onChange='changeStatus')
			.d-flex
				//.btn.btn-analytics.comebacker__btn-analytics(@click="$router.push({name: 'project_analytics', query: { tab: 2 }})")
				//	svg.mr-10.flex-shrink-0(width='18' height='18' viewbox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg')
				//		path(d='M5.16016 13.613V12.0605' stroke-width='1.5' stroke-linecap='round')
				//		path(d='M9 13.6128V10.5078' stroke-width='1.5' stroke-linecap='round')
				//		path(d='M12.8398 13.6123V8.94727' stroke-width='1.5' stroke-linecap='round')
				//		path(d='M12.8402 4.38672L12.4952 4.79172C10.5827 7.02672 8.01766 8.60922 5.16016 9.32172'  stroke-width='1.5' stroke-linecap='round')
				//		path(d='M10.6426 4.38672H12.8401V6.57672' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				//		path(d='M11.25 16.5C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					span.btn-analytics__desktop {{ $t('promotions.comebacker.goAnalytics.btn') }}
					span.btn-analytics__mobile {{ $t('promotions.comebacker.goAnalytics.btnMobile') }}

				.btn.btn-primary.plus-primary.comebacker__btn(@click="$emit('click', $event)")
					span.plus-primary
					span(v-if="!isMobile") {{ $t('promotions.comebacker.createEvent.btn') }}

		promotions-card.mb-4(v-for="(card, i) in listFiltered" :key="card.id" :card="card" :promotions="types.comebacker" :customClass="card.isArchived() ? 'popover-resources is-archive' : 'popover-resources'" @goToPlans="$emit('goToPlans')")

		.d-flex.flex-column.w-100.comebacker__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center 😢
			span.b1.text-center.comebacker__txt {{ $t('project.notArchivedComebackers') }}

		.d-flex.flex-column.w-100.comebacker__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center 😢
			span.b1.text-center.comebacker__txt {{ $t('project.notActivedComebackers') }}
			span.b3.text-center.text-grey {{ $t('project.createNewComebackers') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import { STATUS_ACTIVE } from '@/models/model';
import { mapGetters } from 'vuex';
import { PLAN_TABS } from '@/models/project';

export default {
	name: 'Comebacker',
	components: {
		DropdownStatus,
		PromotionsCard
	},
	data: () => ({
		selectedStatus: null,
		types: PLAN_TABS
	}),
	computed: {
		...mapGetters({
			comebackersList: 'comebackers/items'
		}),
		listFiltered() {
			return _.filter( this.comebackersList, i => this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived() );
		},
	},
	methods: {
		changeStatus(i) {
			this.selectedStatus = i;
		},
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.comebacker {
	&__header {
		margin-bottom: 20px;
	}

	&__btn {
		min-width: 223px;

		@include respond-below(sm-new) {
			min-width: 50px;
			min-height: 50px;

			.plus-primary {
				margin: 0;
			}
		}

		@media (max-width: 375px) {
			display: none;
		}
	}

	&__wrap {
		margin-top: 165px;

		@include respond-below(sm-new){
			margin-top: 132px;
		}
	}

	&__txt {
		margin-top: 11px;
		margin-bottom: 11px;
	}

	&__btn-analytics {
		margin-right: 20px;

		@include respond-below(xs) {
			margin-right: 8px;
		}

		@media (max-width: 375px) {
			margin-right: 0;
		}
	}
}
</style>
