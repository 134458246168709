<template lang='pug'>
	b-card.b1.promotions-info(no-body)
		.d-flex.align-items-center.mb-4.text-nowrap
			router-link.text-grey(to="/tutorials" target="_blank") {{ `${$t('promotions.promotionsInfo.knowledgeBase')}&nbsp&nbsp` }}
			i.fontello.fontello-icon-forward-arrow.text-grey.promotions-info__arrow
			span(v-if="page == 'promotions'") {{ `&nbsp;&nbsp${$t('promotions.promotionsInfo.tariffsAndPromotions')}` }}
			span(v-if="page == 'resources'") {{ `&nbsp;&nbsp${$t('resources.title')}` }}
		.d-flex.flex-column.flex-md-row.justify-content-between(v-if="page == 'promotions'")
			.d-flex.flex-column.b3.text-grey.promotions-info__item
				span.mb-3 {{ $t('promotions.tabs.plans') }}
				pw-btn-link.mb-3(v-for="(item, i) in plansLinkList" :key="`plan-${i}`" :text="item.text" :link="item.link" :blank="item.blank")
			.d-flex.flex-column.b3.text-grey.promotions-info__item
				span.mb-3.mt-3.mt-md-0 {{ $t('promotions.tabs.stock') }}
				pw-btn-link.mb-3(v-for="(item, i) in stockLinkList" :key="`stock-${i}`" :text="item.text" :link="item.link" :blank="item.blank")
			.d-flex.flex-column.b3.text-grey.promotions-info__item
				span.mb-3.mt-3.mt-md-0 {{ $t('promotions.tabs.promoCodes') }}
				pw-btn-link.mb-3(v-for="(item, i) in promoCodesLinkList" :key="`promoCodes-${i}`" :text="item.text" :link="item.link" :blank="item.blank")

		.d-flex.flex-column.flex-md-row(v-if="page == 'resources'")
			.d-flex.flex-column.b3.text-grey.promotions-info__item.promotions-info__item--resources
				pw-btn-link(:text="$t('tutorials.addChannelTitle')" :link="{name: 'addchannel'}" :blank="true")
			.d-flex.flex-column.b3.text-grey.m-0.promotions-info__item.promotions-info__item--resources
				pw-btn-link(:text="$t('tutorials.addGroupTitle')" :link="{name: 'addgroup'}" :blank="true")
</template>

<script>
import PwBtnLink from '@/components/Buttons/PwBtnLink';

export default {
	name: 'PromotionsInfo',
	components: {
		PwBtnLink
	},
	props: {
		page: {
			default: '',
			type: String
		}
	},
	data: () => ({
		plansLinkList: [],
		stockLinkList: [],
		promoCodesLinkList: [],
	}),
	created() {
		this.getLinkList();
	},
	methods: {
		getLinkList() {
			this.plansLinkList = [
				{link: {name: 'tutorialPromotionLimits'}, text: this.$t('promotions.promotionsInfo.plans[0]'), blank: true},
				{link: {name: 'tutorialPromotionLimits'}, text: this.$t('promotions.promotionsInfo.plans[1]'), blank: true},
				{link: {name: 'tutorialPayMore'}, text: this.$t('promotions.promotionsInfo.plans[2]'), blank: true},
				{link: {name: 'tutorialPromotionLimits'}, text: this.$t('promotions.promotionsInfo.plans[3]'), blank: true}
			];

			this.stockLinkList = [
				{link: {name: 'tutorialPromotionHowItWorks'}, text: this.$t('promotions.promotionsInfo.stock[0]'), blank: true},
				{link: {name: 'tutorialPromotionAdd'}, text: this.$t('promotions.promotionsInfo.stock[1]'), blank: true},
				{link: {name: 'tutorialPromotionRemove'}, text: this.$t('promotions.promotionsInfo.stock[2]'), blank: true},
			];

			this.promoCodesLinkList = [
				{link: {name: 'tutorialPromocodeHowItWorks'}, text: this.$t('promotions.promotionsInfo.promoCodes[0]'), blank: true},
				{link: {name: 'tutorialPromocodeAdd'}, text: this.$t('promotions.promotionsInfo.promoCodes[1]'), blank: true},
				{link: {name: 'tutorialPromocodeRemove'}, text: this.$t('promotions.promotionsInfo.promoCodes[2]'), blank: true},
			];
		}
	},
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.promotions-info {
	background-color: var(--foreground-color);
	padding: 20px;

	@include respond-below(sm) {
		padding: 16px;
	}

	&__arrow {
		font-size: 12px;
	}

	&__item {
		width: calc(33.33% - 16px);

		@include respond-below(sm) {
			width: 100%;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&--resources {
			margin-right: 24px;

			@include respond-below(sm) {
				margin-right: 0;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.pw-btn-link {
		&:last-child {
			margin-bottom:  0 !important;
		}
	}
}
</style>
