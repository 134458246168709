<template lang='pug'>
	div
		.d-flex.position-relative.my-input-search(:class="[{'is-focused': isFocused, 'is-float': value && !isFocused}]")
			input.b2.w-100.my-input-search__input(
				autocomplete='off'
				@input="$emit('input', $event.target.value)"
				:value="value"
				type="text"
				:id="`input-${_uid}`"
				:placeholder="placeholder"
				@focus="isFocused = true, $emit('focus')"
				@blur="isFocused = false, $emit('blur')"
			)
			icon-close.my-input-search__reset(v-if="value" @click.prevent="$emit('input', '')")
</template>

<script>
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'MyInputSearch',
	components: {
		IconClose
	},
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		value: {
			default: null,
		},
	},
	data: () => ({
		isFocused: false
	})
}
</script>

<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.my-input-search {
	background-color: var(--grey-bg-color);
	border-radius: var(--border-radius-rounded);

	&::before {
		z-index: 1;
		position: absolute;
		content: '';
		top: 50%;
		left: 13px;
		width: 16px;
		height: 16px;
		mask-image: url('/assets/img/icons/search.svg');
		mask-repeat: no-repeat;
		mask-size: cover;
		background-color: var(--primary-text-color);
		transform: translateY(-50%);
	}

	&.is-focused {
		background-color: var(--foreground-color);

		.my-input-search__input {
			color: var(--main-text-color);
		}
	}

	&.is-float {
		background-color: var(--grey-bg-color);

		.my-input-search__input {
			color: var(--main-text-color);
		}
	}

	&__input {
		z-index: 1;
		align-items: center;
		height: 31px;
		padding: 4px 36px 3px 36px;
		border: 1.5px solid transparent;
		background-color: transparent;
		border-radius: var(--border-radius-rounded);
		transition: var(--animation-time-short);
		cursor: text;

		&:hover {
			background-color: transparent !important;
			border: 1.5px solid var(--button-border-color) !important;
		}

		&:focus {
			background-color: transparent !important;
			border: 1.5px solid var(--button-border-color) !important;
		}
	}

	&__reset {
		z-index: 1;
		position: absolute;
		top: 50%;
		right: 13px;
		transform: translateY(-50%);
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}
}
</style>
