<template lang="pug">
	.mb-4.d-flex.flex-wrap.justify-content-between.stock(v-if='list && list.length > 0')
		.d-flex.justify-content-between.align-items-center.w-100.stock__header
			dropdown-status(@onChange='changeStatus')
			.btn.btn-primary.plus-primary.stock__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('promotions.stock.createEvent.btn') }}
		promotions-card.mb-4(v-for="(card, i) in listFiltered" :key="card.id" :card="card" :promotions="types.stock" :customClass="card.isArchived() ? 'popover-resources is-archive' : 'popover-resources'")

		.d-flex.flex-column.w-100.stock__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center 😢
			span.b1.text-center.stock__txt {{ $t('project.notArchivedPromotion') }}

		.d-flex.flex-column.w-100.stock__wrap(v-if="listFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center 😢
			span.b1.text-center.stock__txt {{ $t('project.notActivedPromotion') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstPromotion') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import {STATUS_ACTIVE} from '@/models/model'
import { mapGetters } from 'vuex';
import {PLAN_TABS} from '@/models/project'

export default {
	name: 'Stocks',
	components: {
		DropdownStatus,
		PromotionsCard
	},
	data: () => ({
		selectedStatus	: null,
		types 			: PLAN_TABS
	}),
	computed: {
		...mapGetters({
			project : 'project/opened',
			list 	: 'project/promotions'
		}),

		listFiltered () {
			return _.filter( this.list, i => this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived() );
		},
	},
	methods: {
		changeStatus ( i ) {
			this.selectedStatus = i;
		},
	},
}
</script>
<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.stock {
	&__header {
		margin-bottom: 20px;
	}

	&__btn {
		min-width: 223px;

		@include respond-below(sm-new) {
			min-width: 50px;
			min-height: 50px;

			.plus-primary {
				margin: 0;
			}
		}
	}

	&__wrap {
		margin-top: 165px;

		@include respond-below(sm-new){
			margin-top: 132px;
		}
	}

	&__txt {
		margin-top: 11px;
		margin-bottom: 11px;
	}
}
</style>
