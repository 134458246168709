<template lang="pug">
	.mb-4.d-flex.flex-wrap.justify-content-between.tariff(v-if='plans && plans.length > 0')
		.d-flex.justify-content-between.align-items-center.w-100.tariff__header
			dropdown-status(@onChange='changeStatus')
			.btn.btn-primary.plus-primary.tariff__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('promotions.tariff.createEvent.btn') }}
		promotions-card.mb-4(v-for="(card, i) in plansFiltered" :key="card.id" :card="card" :promotions="types.tariff" :customClass="card.isArchived() ? 'popover-resources is-archive' : 'popover-resources'")

		.d-flex.flex-column.w-100.tariff__wrap(v-if="plansFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center 😢
			span.b1.text-center.tariff__txt {{ $t('project.notArchivedRates') }}

		.d-flex.flex-column.w-100.tariff__wrap(v-if="plansFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center 😢
			span.b1.text-center.tariff__txt {{ $t('project.notActivedPlans') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstRate') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import { mapGetters } from 'vuex';
import { STATUS_ACTIVE } from '@/models/model';
import { PLAN_TABS } from '@/models/project';

export default {
	name: 'Tariff',
	components: {
		DropdownStatus,
		PromotionsCard
	},
	data: () => ({
		selectedStatus: null,
		types: PLAN_TABS
	}),
	computed: {
		...mapGetters({
			plans: 'plans/list'
		}),
		plansFiltered () {
			return _.filter( this.plans, i => this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived() );
		}
	},
	methods: {
		changeStatus ( i ) {
			this.selectedStatus = i;
		}
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.tariff {
	&__header {
		margin-bottom: 20px;
	}

	&__btn {
		min-width: 223px;

		@include respond-below(sm-new) {
			min-width: 50px;
			min-height: 50px;

			.plus-primary {
				margin: 0;
			}
		}
	}

	&__wrap {
		margin-top: 165px;

		@include respond-below(sm-new){
			margin-top: 132px;
		}
	}

	&__txt {
		margin-top: 11px;
		margin-bottom: 11px;
	}
}
</style>
