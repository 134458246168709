<template lang="pug">
	div
		.d-flex.position-relative.dropdown-label(:class="[icon, {'is-float': selected != '', 'blocked' : disabled, 'is-error': errorFeedback != '' || isError}]")
			span.m-0.b3.dropdown-label__label(:class="{'b4': selected != ''}" v-html="label")
			b-dropdown.dropdown.b3.w-100.dropdown-label__dropdown.animated(ref="dropdownLabel" :id="id" menu-class='p-0 m-0 b3' :disabled='disabled')
				template(#button-content)
					span(v-html="selected")
				.position-sticky.dropdown-label__search(v-if="searchable")
					my-input-search.w-100(:placeholder="$t('dashboards.search')" @input="getSearchText")
				.d-flex.flex-column.py-5.dropdown-label__search-empty(v-if="searchable && list.length == 0 && searchText.length > 3")
					h1.mb-3.text-center 😢
					p.b4.mb-0.text-grey.text-center {{ $t('errors.emptySearch') }}
				b-dropdown-item(v-for='(item, i) in list' :key='i' @click="selected = searchable ? item.value : item.title, $emit('onChange', searchable ? item.unrestricted_value : item.id)" )
					span(v-html="searchable ? item.value : item.title")
					svg.ml-3(v-if="selected == item.value || selected == item.title" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
						path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
		.text-danger.b4.mt-1(v-if="errorFeedback != ''" v-html='errorFeedback')
</template>

<script>
import MyInputSearch from "@/components/UI/MyInputSearch";

export default {
	name: 'DropdownLabel',
	props: {
		list: {
			default: () => [],
			type: Array
		},
		id: {
			default: '',
			type: String
		},
		icon: {
			default: '',
			type: String
		},
		label: {
			default: '',
			type: String
		},
		selectedId: {
			default: null,
		},
		searchable: {
			default: false,
			type: Boolean
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isError: {
			type: Boolean,
			default: false,
		},
		errorFeedback: {
			type: String,
			default: '',
		}
	},
	components: {
		MyInputSearch
	},
	data: () => ({
		selected: '',
		searchText: ''
	}),
	created() {
		if(this.selectedId) {
			this.getSelected();
		}
	},
	mounted() {
		this.$root.$on('bv::dropdown::hide', bvEvent => {
			this.$emit('dropdownHide', bvEvent.componentId)
		})
	},
	methods: {
		getSelected() {
			this.list.forEach(x => {
				if(x.id == this.selectedId || x.unrestricted_value == this.selectedId) {
					this.selected = this.searchable ? x.value : x.title;
				}
			});
		},
		getSearchText(data) {
			this.searchText = data;
			this.$emit('search', data);
		}
	},
	watch: {
		selectedId () {
			this.getSelected();
		}
	}
}
</script>
<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.dropdown-label {
	border-radius: var(--border-radius-rounded);

	&.is-error {
		.dropdown-toggle,
		.show .dropdown-toggle {
			border: 1.5px solid var(--red-bg-color) !important;

			&:hover,
			&:focus {
				border: 1px solid var(--red-bg-color) !important;
			}

			&::after {
				background: var(--red-text-color);
			}
		}

		.dropdown-label__label {
			color: var(--red-bg-color);
		}

		&::before {
			background-color: var(--red-text-color);
		}
	}

	&__dropdown {
		height: 52px;

		&:hover,
		&:active,
		&:focus {
			button {
				border: 1px solid var(--button-border-color) !important;
				background-color: var(--foreground-color) !important;
				color: var(--main-text-color) !important;
			}
		}

		button {
			justify-content: flex-start;
			height: 52px;
			padding-left: 50px !important;
			padding-right: 20px !important;
			font-size: 14px;
			line-height: calc(22/14);
			border: 1px solid var(--border-line) !important;
			background-color: var(--foreground-color) !important;
			border-radius: var(--border-radius-rounded) !important;
			color: var(--main-text-color) !important;
			box-shadow: none !important;
			overflow: hidden !important;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 20px;
			}
		}

		.show {
			box-shadow: none !important;
		}

		.dropdown-toggle {
			&::after {
				position: absolute;
				top: 50%;
				right: 20px;
				width: 18px;
				height: 18px;
				mask-image: url('/assets/img/icons/arrow.svg');
				background: var(--button-border-color);
				border: none !important;
				transform: translateY(-50%);
			}
		}

		&.show .dropdown-toggle {
			border: 1px solid var(--button-border-color) !important;
			border-bottom-left-radius: var(--border-radius-rounded) !important;
			border-bottom-right-radius: var(--border-radius-rounded) !important;
			background-color: var(--foreground-color) !important;
			color: var(--main-text-color) !important;

			&::after {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background: url('/assets/img/icons/arrow-down.svg');
			}
		}

		.dropdown-menu {
			top: 28px !important;
			width: 100%;
			background-color: var(--background-color) !important;
			border-radius: var(--border-radius-rounded) !important;
			box-shadow: var(--box-shadow) !important;

			/* Скрываем scrollbar для IE, Edge и Firefox */
			-ms-overflow-style: none;  /* IE и Edge */
			scrollbar-width: none;  /* Firefox */

			/* Скрываем scrollbar для Chrome, Safari и Opera */
			&::-webkit-scrollbar {
				display: none;
			}
		}

		&.show ul li:first-child:before {
			content: none !important;
		}

		.dropdown-item {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px !important;
			color: var(--main-text-color) !important;
			background-color: var(--foreground-back);
			transition: var(--animation-time-short);

			&::after {
				z-index: 1;
				content: '';
				position: absolute;
				bottom: 0;
				left: 15px;
				right: 0;
				height: 0.5px ;
				background-color: var(--border-line);
			}

			&:hover,
			&:active,
			&:focus {
				background-color: var(--grayscale-bg-dark-back) !important;
				color: var(--main-text-color) !important;
			}
		}

		&.dropdown ul li:last-child {
			.dropdown-item {
				&::after {
					content: none;
				}
			}
		}

		&.animated {
			.dropdown-menu {
				overflow: auto;
				opacity: 0;
				display: block!important;
				max-height: 0!important;
				transition: max-height var(--animation-time-short) ease-in-out;
				box-shadow: var(--box-shadow-hover) !important;

				&:not(.show) {
					padding: 0;
					border: none;
				}

				&.show {
					opacity: 1;
					transition: max-height var(--animation-time-short) ease-in-out;
					border: 1px solid var(--border-line) !important;
					max-height: 360px!important;
				}
			}
		}
	}

	&.is-float {
		background-color: var(--grey-bg-color);

		button {
		padding: 24px 20px 6px 50px !important;
		}

		.dropdown-label__label {
			top: 30%;
		}
	}

	&::before {
		z-index: 2;
		position: absolute;
		content: '';
		top: 50%;
		left: 20px;
		background-color: var(--primary-text-color);
		background-size: cover;
		mask-repeat: no-repeat;
		transform: translateY(-50%);
		cursor: pointer;
		pointer-events: none;
	}

	&.icon-gift {
		&::before {
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/gift.svg');
		}
	}

	&.icon-buy-crypto {
		&::before {
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/buy-crypto.svg');
		}
	}

	&.icon-calendar {
		&::before {
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/calendar.svg');
		}
	}

	&.icon-house {
		&::before {
			width: 20px;
			height: 20px;
			mask-image: url('/assets/img/icons/house.svg');
		}
	}

	&__label {
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50px;
		color: var(--grey-text-color);
		transform: translateY(-50%);
		cursor: text;
		transition: var(--animation-time-short);
		cursor: pointer;
		pointer-events: none;
	}

	&__search {
		z-index: 2;
		top: 0%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px !important;
		color: var(--main-text-color) !important;
		background-color: var(--foreground-back);
	}

	&__search-empty {
		background-color: var(--foreground-back);
	}
}
</style>
